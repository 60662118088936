<template>
  <div class="container">
    <div class="row col-12">
      <div class="card border-0 shadow-none">
        <div class="card-header h4">Shortage export</div>
        <div class="card-body">
          <div class="form-group row d-flex">
            <label class="col-sm-3 col-form-label form-label text-right">Weekly Menu</label>
            <div class="col-sm-7">
              <select
                v-model="selected.weekly_menu_id"
                :class="`form-control custom-select ${validator.errors().has('weekly_menu_id') ? 'is-invalid' : ''}`">
                <option :value="null">Select weekly menu</option>
                <option
                  v-for="menu in weeklyMenus"
                  :key="menu.id"
                  :value="menu.id">
                  {{menu.startDate}}
                </option>
              </select>
              <div v-if="validator.errors().has('weekly_menu_id')" class="invalid-feedback">
                {{validator.errors().first('weekly_menu_id')}}
              </div>
            </div>
          </div>
          <div class="form-group row d-flex">
            <label class="col-sm-3 col-form-label form-label text-right">Delivery day</label>
            <div class="col-sm-7">
              <select
                v-model="selected.delivery_day_id"
                :class="`form-control custom-select ${validator.errors().has('delivery_day_id') ? 'is-invalid' : ''}`">
                <option :value="null">Select delivery day</option>
                <option
                  v-for="day in deliveryDays"
                  :key="day.id"
                  :value="day.id">
                  {{day.name}}
                </option>
              </select>
              <div v-if="validator.errors().has('delivery_day_id')" class="invalid-feedback">
                {{validator.errors().first('delivery_day_id')}}
              </div>
            </div>
          </div>
        </div>
        <div v-if="can(uiPermissions.PURCHASE_ORDERS_SHORTAGE_EXPORT)" class="card-footer">
          <button class="btn btn-primary" @click.prevent="exportShortage">Export</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {deliveryDays, weeklyMenusBasic, purchaseOrderRoster} from '@/services';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import moment from 'moment-timezone';
import download from '@/mixins/download';

export default {
  name: 'Shortage',
  mixins: [download],
  data() {
    return {
      weeklyMenus: [],
      deliveryDays: [],
      selected: {
        weekly_menu_id: null,
        delivery_day_id: null,
      },
      rules: {
        weekly_menu_id: 'required',
        delivery_day_id: 'required',
      },
      validator: this.$bodyValidator,
    };
  },
  created() {
    deliveryDays.getByParameters({
    }).then(result => {
      this.deliveryDays = result.items;
    });

    weeklyMenusBasic.getByParameters({
      column: 'id',
      direction: 'asc',
      limit: 4,
      startDateFrom: thursdayBasedWeek(moment().subtract(1, 'weeks')).format('YYYY-MM-DD'),
      status: ['published'],
    }).then(result => {
      this.weeklyMenus = result.items;
    });
  },
  methods: {
    async exportShortage() {
      this.validator
        .setData(this.selected)
        .setRules(this.rules);

      if (!this.validator.validate()) {
        return;
      }

      return this.download(await purchaseOrderRoster.exportShortage(this.selected));
    },
  },
};
</script>
